import React from 'react';

const About = () => {
  const containerStyle = {
    maxWidth: '800px',
    margin: 'auto',
    marginTop : '100px',
    padding: '20px',
    backgroundColor: '#f5f5f5',
    borderRadius: '8px',
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
  };

  const headingStyle = {
    fontSize: '24px',
    color: '#333',
    marginBottom: '20px',
  };

  const paragraphStyle = {
    fontSize: '16px',
    lineHeight: '1.6',
    color: '#555',
    marginBottom: '15px',
  };

  return (
    <div style={containerStyle}>
      <h2 style={headingStyle}>About</h2>
      <p style={paragraphStyle}>
        Welcome to our news website! We strive to bring you the latest and most relevant news from around the world. Our dedicated team of journalists works tirelessly to keep you informed about the events and stories that matter.
      </p>
      <p style={paragraphStyle}>
        Whether it's breaking news, in-depth analysis, or feature stories, you can count on us to deliver accurate and timely information. Explore our website to stay updated on a wide range of topics and global affairs.
      </p>
      <p style={paragraphStyle}>Thank you for choosing us as your trusted source for news!</p>
    </div>
  );
};

export default About;
