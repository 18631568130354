import './App.css';
import About from './Components/About';
import Home from './Components/Home';
import { Routes, Route } from "react-router-dom";
import Navbar from './Components/Navbar';
import { useState } from 'react';
import Footer from './Components/Footer';
function App() {


  const [searchTerm, setSearchTerm] = useState('');

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  return (
    <>
    <Navbar onSearchChange={handleSearchChange} />
    
    <Routes>
        <Route path="/" element={<Home searchTerm={searchTerm} />} />
        <Route path="/about" element={<About/>}/>
    </Routes> 
    <Footer/>
    </>
  );
}

export default App;
