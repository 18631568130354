import React, { useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const Carousel = ({ articles }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,        // Enable autoplay
    autoplaySpeed: 3000,
    beforeChange: (current, next) => setCurrentIndex(next),
  };

  if (!articles || articles.length === 0) {
    return <p>No articles to display</p>;
  }

  return (
    <div  style={{right:"0px",marginRight:"0px" , width:"100%"}}>
      <Slider {...settings}>
        {articles.map((article, index) => (
          <div key={index}>
            <img src={article.urlToImage} width="100%" height="500px" alt={`Slide ${index + 1}`} />
            <p style={{textAlign:"center"}}>{article.title}</p>
            <p style={{textAlign:"center"}}>{`${currentIndex + 1}/${articles.length}`}</p>
          </div>
        ))}
        </Slider>
    </div>
  );
};

export default Carousel;
