/* Navbar.js */

import React from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css';
import logo from './news.png'
const Navbar = ({ onSearchChange }) => {
  return (
    <div className="navbar-container">
      <div className="news-heading">
        <img src={logo} width={'50px'} alt='...'/> &nbsp;
        NEWS</div>
      <div className="nav-links">
        <Link to="/" className="nav-link">Home</Link>
        <Link to="/about" className="nav-link">About</Link>
      </div>
      <input
        type="text"
        className="search-input"
        placeholder="Search for news..."
        onChange={onSearchChange}
      />
    </div>
  );
};

export default Navbar;
