// NewsDisplay.js
import React from 'react';
import './NewsDisplay.css';

const NewsDisplay = ({ articles }) => {
    return (
      <div className="news-container">
        {articles.map((article, index) => (
          <div key={index} className="news-card">
            {article.urlToImage && (
              <img src={article.urlToImage} alt={article.title} className="news-image" />
            )}
            <div className="news-details">
              <h2 className="news-title">{article.title}</h2>
              <p className="news-description">{article.description}</p>
              <a href={article.url} className="read-more" target="_blank" rel="noopener noreferrer">
                Read More
              </a>
            </div>
          </div>
        ))}
      </div>
    );
  };

export default NewsDisplay;
