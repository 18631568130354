import React, { useState, useEffect } from 'react';
import NewsDisplay from './NewsDisplay';
import Carousel from './Carousel';

const Home = ({ searchTerm }) => {
  const [newsData, setNewsData] = useState(null);


  
  const [articles, setArticles] = useState([]);

  useEffect(() => {
    const fetchCarouselImages = async () => {
      try {
        const response = await fetch('https://news-server-3bp6.onrender.com/api/carousel');
        const data = await response.json();
        console.log(data)
        setArticles(data.articles);
      } catch (error) {
        console.error('Error fetching carousel images:', error);
      }
    };

    fetchCarouselImages();
  }, []);

  useEffect(() => {
    // Define the API endpoint for fetching news
    const apiEndpoint = searchTerm
      ? `https://news-server-3bp6.onrender.com/api/search?searchTerm=${searchTerm}`
      : 'https://news-server-3bp6.onrender.com/api/top-headlines';
  
    // Fetch data from the server
    const fetchData = async () => {
      try {
        const response = await fetch(apiEndpoint);
        const data = await response.json();
        setNewsData(data);
      } catch (error) {
        console.error('Error fetching news:', error);
      }
    };
  
    fetchData();
  }, [searchTerm]);
  

  return (
    <div>
        <Carousel articles={articles} />
      <div className="content-container">
        {newsData ? (
          <div>
            <h2>{searchTerm ? `Search Results for "${searchTerm}"` : 'Top Headlines'}</h2>
            {/* Add your logic to display search results or top headlines here */}
            <NewsDisplay articles={newsData.articles} />
          </div>
        ) : (
          <p>Loading...</p>
        )}
      </div>
    </div>
  );
};

export default Home;
